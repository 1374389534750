:root {
  // <Primitives>

  // <Colors>
  //
  // Primitive colors are not be used directly in the application.
  // They are used to create tokens.
  //
  // <Grey>
  --fivef-color-grey-100: #FFFFFF;
  --fivef-color-grey-200: #F9FAFB;
  --fivef-color-grey-300: #F3F5F6;
  --fivef-color-grey-400: #EBEDEF;
  --fivef-color-grey-500: #DDE0E4;
  --fivef-color-grey-600: #CDD1D5;
  --fivef-color-grey-700: #C2C7CB;
  --fivef-color-grey-800: #ACB3B9;
  --fivef-color-grey-900: #9FA6AD;
  // </Grey>

  // <DarkGrey>
  --fivef-color-dark-grey-100: #949596;
  --fivef-color-dark-grey-200: #7E7F80;
  --fivef-color-dark-grey-300: #727374;
  --fivef-color-dark-grey-400: #5F6163;
  --fivef-color-dark-grey-500: #3E3F41;
  --fivef-color-dark-grey-600: #282A2C;
  --fivef-color-dark-grey-700: #242628;
  --fivef-color-dark-grey-800: #202223;
  --fivef-color-dark-grey-900: #1C1D1F;
  // </DarkGrey>

  // <FiveBlue>
  --fivef-color-fivef-blue-100: #f4f5f6;
  --fivef-color-fivef-blue-200: #EFF0F1;
  --fivef-color-fivef-blue-300: #e9eaec;
  --fivef-color-fivef-blue-400: #d3d6da;
  --fivef-color-fivef-blue-500: #a7adb5;
  --fivef-color-fivef-blue-600: #7b8490;
  --fivef-color-fivef-blue-700: #4e5a6a;
  --fivef-color-fivef-blue-800: #384658;
  --fivef-color-fivef-blue-900: #233246;
  // </FiveBlue>

  // <FiveSkyBlue>
  --fivef-color-fivef-sky-blue-100: #f5fafb;
  --fivef-color-fivef-sky-blue-200: #edf5f7;
  --fivef-color-fivef-sky-blue-300: #dbebf0;
  --fivef-color-fivef-sky-blue-400: #b7d7e1;
  --fivef-color-fivef-sky-blue-500: #93c3d2;
  --fivef-color-fivef-sky-blue-600: #6fafc3;
  --fivef-color-fivef-sky-blue-700: #5da4bb;
  --fivef-color-fivef-sky-blue-800: #4b9bb4;
  // </FiveSkyBlue>

  // <FiveCoolGray>
  --fivef-color-fivef-cool-gray-100: rgba(245, 245, 245, 0.8);
  --fivef-color-fivef-cool-gray-200: #ebebeb;
  --fivef-color-fivef-cool-gray-300: #d8d8d8;
  --fivef-color-fivef-cool-gray-400: #b1b1b1;
  --fivef-color-fivef-cool-gray-500: #8a8a8a;
  --fivef-color-fivef-cool-gray-600: #626262;
  --fivef-color-fivef-cool-gray-700: #4f4f4f;
  --fivef-color-fivef-cool-gray-800: #3c3c3c;
  --fivef-color-fivef-cool-gray-900: #233246;
  // </FiveCoolGray>


  // <Blue>
  --fivef-color-blue-100: #D3E8FF;
  --fivef-color-blue-200: #A1CEFF;
  --fivef-color-blue-300: #66AFFF;
  --fivef-color-blue-400: #4DA2FF;
  --fivef-color-blue-500: #007AFF;
  --fivef-color-blue-600: #0062CC;
  --fivef-color-blue-700: #0055B3;
  --fivef-color-blue-800: #004999;
  --fivef-color-blue-900: #01366F;
  // </Blue>

  // <Red>
  --fivef-color-red-100: #FFE6E6;
  --fivef-color-red-200: #FFCCCC;
  --fivef-color-red-300: #FFB3B3;
  --fivef-color-red-400: #FF9999;
  --fivef-color-red-500: #FF8080;
  --fivef-color-red-600: #FF6666;
  --fivef-color-red-700: #FF4D4D;
  --fivef-color-red-800: #FF3333;
  --fivef-color-red-900: #FF1A1A;
  // </Red>

  // <Green>
  --fivef-color-green-100: #E6FFE6;
  --fivef-color-green-200: #CCFFCC;
  --fivef-color-green-300: #B3FFB3;
  --fivef-color-green-400: #99FF99;
  --fivef-color-green-500: #80FF80;
  --fivef-color-green-600: #66FF66;
  --fivef-color-green-700: #4DFF4D;
  --fivef-color-green-800: #33FF33;
  --fivef-color-green-900: #1AFF1A;
  // </Green>

  // <Yellow>
  --fivef-color-yellow-100: #FFFFE6;
  --fivef-color-yellow-200: #FFFFCC;
  --fivef-color-yellow-300: #FFFFB3;
  --fivef-color-yellow-400: #FFFF99;
  --fivef-color-yellow-500: #FFFF80;
  --fivef-color-yellow-600: #FFFF66;
  --fivef-color-yellow-700: #FFFF4D;
  --fivef-color-yellow-800: #FFFF33;
  --fivef-color-yellow-900: #FFFF1A;
  // </Yellow>

  // <Brand>
  --fivef-color-brand-blue-500: #007BFF;
  --fivef-color-brand-darkblue-500: #243347;
  // </Brand>

  // </Colors>

  // <BoxShadow>
  --fivef-box-shadow-s: 0px 1px 1px 0px rgba(37, 50, 70, 0.12);
  --fivef-box-shadow-m: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --fivef-box-shadow-l: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  // </BoxShadow>

  // <Transitions>
  --fivef-color-transition-m: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
  // </Transitions>

  // <Transparent cells on tables for horizontal scrolling>
  // Formerly: $dvtx-cool-grey-25
  // Note: transparentize requires as compiled function real colors not runtime colors by variables.
  //       The color is calculated at compile time.
  --fivef-color-table-transparent-background: linear-gradient(to right, white 0%, transparentize(white, 0.1) 75%, transparentize(white, 0.2) 100%);
  --fivef-color-table-header-transparent-background: linear-gradient(to right, #f5f5f5 0%, transparentize(#f5f5f5, 0.1) 75%, transparentize(#f5f5f5, 0.2) 100%);
  // </Transparent cells on tables for horizontal scrolling>

  // <Primitives>


  // <Tokens>

  // <5F Concepts>
  --fivef-color-surface-private: var(--fivef-color-fivef-sky-blue-800);
  --fivef-color-surface-private-highlight: var(--fivef-color-fivef-sky-blue-700);
  // </5F Concepts>

  // <Text>
  --fivef-color-text-primary: var(--fivef-color-fivef-blue-900);
  --fivef-color-text-secondary: var(--fivef-color-fivef-blue-800);
  // lighter color scheme for highlight events when used in secondary context, e.g. at sidebar link items.
  --fivef-color-text-secondary-highlight: var(--fivef-color-fivef-blue-700);

  --fivef-color-text-tertiary: var(--fivef-color-fivef-blue-600);
  --fivef-color-text-disabled: var(--fivef-color-fivef-blue-400);
  --fivef-color-text-inverse: var(--fivef-color-fivef-blue-300);
  --fivef-color-text-brand: var(--fivef-color-fivef-blue-900);
  --fivef-color-text-upload: var(--fivef-color-fivef-blue-900);
  --fivef-color-text-upload-hover: white;
  // </Text>

  // <Surface>
  --fivef-color-surface-primary: var(--fivef-color-grey-100);
  // Slightly transparent background color used at INBOX and comments for scrolling items below header:
  --fivef-color-surface-primary-transparent: #f5f5f5e6;
  --fivef-color-surface-primary-highlight: var(--fivef-color-fivef-cool-gray-200);
  --fivef-color-surface-secondary: var(--fivef-color-grey-200);
  --fivef-color-surface-tertiary: var(--fivef-color-grey-300);
  --fivef-color-surface-disabled: var(--fivef-color-grey-400);
  --fivef-color-surface-inverse: var(--fivef-color-dark-grey-900);
  --fivef-color-surface-brand: var(--fivef-color-blue-500);
  // </Surface>

  // <Sidebar>
  --fivef-color-sidebar-surface: var(--fivef-color-app-background);
  // TODO: How make a given color by HEX CSS var slightly transparent?
  --fivef-color-sidebar-scroll-background: rgba(255, 255, 255, 0.85);
  --fivef-color-sidebar-resize-handle: var(--fivef-color-action);
  --fivef-sidebar-box-shadow: unset; // rgb(0 0 0 / 5%) 0px 2px 5px 0px, rgb(0 0 0 / 3%) 0px 2px 10px 0px;
  --fivef-sidebar-border-right: 1px solid var(--fivef-page-header-border-color);
  --fivef-sidebar-background-color: var(--fivef-color-app-background);
  // </Sidebar>

  // <Hover & Action>
  --fivef-color-action: #007aff;
  --fivef-color-action-highlight: #2196F3;
  --fivef-color-action-light: rgb(230, 246, 253);
  --fivef-color-warn: #db0000;
  --fivef-color-warn-alt: #db2e2e;
  --fivef-color-warn-light: rgb(248, 204, 204);
  --fivef-color-overdue: #DC4444FF;
  --fivef-color-info: var(--fivef-color-action);
  --fivef-color-success: rgb(0, 210, 0);
  --fivef-color-success-alt: #00ca5c;
  --fivef-color-success-light: rgb(230, 251, 230);
  --fivef-color-status-success: var(--fivef-color-success);
  --fivef-color-status-in-progress: #5bc4f1;
  --fivef-color-status-open: #ff8800;
  --fivef-color-teal: #4db6ac;
  --fivef-color-licensed: var(--fivef-color-teal);
  --fivef-color-teal-highlight: #60e6df;
  --fivef-color-seenby: #00d200;
  --fivef-color-priority-highest: #db0000;
  --fivef-color-priority-high: #ff8800;
  --fivef-color-priority-medium: #0090cf;
  --fivef-color-priority-low: #00ca5c;
  --fivef-color-priority-lowest: #007834;
  --fivef-color-applicable: #32CD32;
  --fivef-color-not-applicable: grey;
  // </Hover & Action>

  /**
   * Info elements colors.
   * E.g.
   * - for tooltip buttons,
   * - fivef-icon-message-block,
   * - ff-info-icon class.
   */
  --fivef-info-color-icon: var(--fivef-color-fivef-blue-500);
  --fivef-info-color-text: var(--fivef-color-text-tertiary);
  --fivef-info-color-border: var(--fivef-color-fivef-blue-500);

  // <Comment & Reaction>
  --fivef-comment-separator-color: var(--fivef-color-border-disabled);
  --fivef-comment-reply-title-color: var(--fivef-color-text-tertiary);
  --fivef-comment-reply-border-color: var(--fivef-color-fivef-blue-600);
  --fivef-comment-reply-bg-color: var(--fivef-color-fivef-blue-100);

  --fivef-color-reaction-success: #00d200;
  --fivef-color-reaction-smiley: #FFDE2DFF;
  --fivef-color-reaction-heart: #DC4444FF;
  --fivef-color-reaction-thump-up: var(--fivef-color-action);
  --fivef-color-reaction-smiley-bg: var(--fivef-color-fivef-blue-800);
  // </Comment & Reaction>

  // <Indicators>
  --fivef-color-indicator-background: #d3d6da;
  --fivef-color-indicator: #233246;
  --fivef-color-indicator-inverse-background: #00d200;
  --fivef-color-indicator-inverse: #00d200;
  --fivef-color-indicator-news-background: #00d200;
  --fivef-color-indicator-news: #00d200;
  // </Indicators>

  // <Vendor>
  --fivef-color-datev: #90d032;
  // </Vendor>

  // <HR and separators>
  --fivef-color-separater: var(--fivef-color-fivef-blue-300);
  --fivef-color-hr: var(--fivef-color-separater);
  // <HR and separators>

  // <Border>
  --fivef-color-border-primary: var(--fivef-color-grey-500);
  --fivef-color-border-secondary: var(--fivef-color-grey-600);
  --fivef-color-border-tertiary: var(--fivef-color-grey-700);
  --fivef-color-border-disabled: var(--fivef-color-grey-600);
  --fivef-color-border-inverse: var(--fivef-color-dark-grey-900);
  --fivef-color-border-brand: var(--fivef-color-blue-500);
  // </Border>


  // Next level of tokens
  // Main app background.
  --fivef-color-app-background: var(--fivef-color-fivef-cool-gray-100);

  --fivef-page-header-background-color: var(--fivef-color-app-background);
  --fivef-page-header-border-color: var(--fivef-color-fivef-blue-400);
  --fivef-page-header-box-shadow: unset; // 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 3%) !important;
  --fivef-page-header-margin: 0 var(--fivef-spacing-m) 0 var(--fivef-spacing-xs);
  --fivef-page-header-link-color: #{$dvtx-fivef-blue-400};
  --fivef-page-header-link-color-hover: #{$dvtx-fivef-blue-500};
  --fivef-page-header-link-box-shadow: #{$dvtx-fivef-blue-500};

  --fivef-app-menu-item-color: #{$dvtx-fivef-blue-400};
  --fivef-app-menu-item-color-hover: white;
  --fivef-app-menu-item-background-color-hover: #{$dvtx-fivef-blue-500};

  /**
   * Sidebar.
   */
  --fivef-nav-link-color: var(--fivef-color-text-tertiary);
  --fivef-nav-link-color-hover: var(--fivef-color-text-primary);
  --fivef-nav-link-border-color-hover: var(--fivef-color-text-primary);
  --fivef-nav-link-color-active: var(--fivef-color-text-secondary);
  --fivef-nav-link-border-color-active: var(--fivef-color-text-secondary);
  --fivef-nav-link-color-current: var(--fivef-color-text-secondary);
  --fivef-nav-link-border-color-current: var(--fivef-color-text-secondary-highlight);

  /**
   * Upload.
   */
  --fivef-upload-color: #e5f5fc;
  --fivef-upload-background-color: #e5f5fc;
  --fivef-upload-border-color: #e5f5fc;

  --fivef-upload-color-hover: var(--fivef-color-text-inverse);
  --fivef-upload-background-color-hover: var(--fivef-color-action-highlight);
  --fivef-upload-border-color-hover: var(--fivef-color-action);

  --fivef-upload-button-color: var(--fivef-color-text-secondary);
  --fivef-upload-button-background-color: var(--fivef-color-action-highlight);
  --fivef-upload-button-border-color: var(--fivef-color-border-primary);

  --fivef-upload-button-color-hover: white;
  --fivef-upload-button-background-color-hover: var(--fivef-color-action);
  --fivef-upload-button-border-color-hover: transparent;

  /**
   * Dashboard related colors.
   */
  --fivef-dashboard-update-icon-color: #243347;
  --fivef-dashboard-widget-colored-header-primary: var(--fivef-color-text-secondary);
  --fivef-dashboard-widget-colored-header-secondary: var(--fivef-color-text-tertiary);

  /**
   * Content max width for UI/UX and readability.
   */
  --fivef-content-max-width: 800px;

  /**
   * Settings panels.
   */
  --fivef-settings-panel-profile-default-background-color: #233246;
  --fivef-settings-panel-profile-default-background-color-hover: #384658;
  --fivef-settings-panel-profile-default-color: white;
  --fivef-settings-panel-profile-default-color-hover: white;
  --fivef-settings-panel-profile-user-background-color: #4b9bb4;
  --fivef-settings-panel-profile-user-background-color-hover: #5da4bb;
  --fivef-settings-panel-profile-user-color: white;
  --fivef-settings-panel-profile-user-color-hover: white;
  --fivef-settings-panel-profile-tenant-background-color: #243347;
  --fivef-settings-panel-profile-tenant-background-color-hover: #243347;
  --fivef-settings-panel-profile-tenant-color: white;
  --fivef-settings-panel-profile-tenant-color-hover: white;
  --fivef-settings-panel-border-radius: var(--fivef-border-radius-m);
  --fivef-settings-panel-box-shadow-hover: 0 16px 16px -16px #{$dvtx-cool-grey-300} !important;
  --fivef-settings-panel-profile-disabled: var(--fivef-color-grey-600);

  /**
   * Inbox.
   */
  --fivef-inbox-notification-bg-color: var(--fivef-color-surface-primary);
  --fivef-inbox-notification-border-radius: var(--fivef-border-radius-m);
  // Fomerly: five-f-elevation-z4.
  --fivef-inbox-notification-box-shadow: 0 16px 16px -16px #c9c9c9;

  // <Icons>
  --fivef-color-icon-primary: var(--fivef-color-text-tertiary);
  --fivef-color-icon-secondary: var(--fivef-color-text-disabled);
  --fivef-color-icon-error: var(--fivef-color-red-700);
  --fivef-color-icon-success: var(--fivef-color-green-500);
  --fivef-color-icon-warning: var(--fivef-color-yellow-500);
  // </Icons>

  // <Buttons>
  // The primary color is light blue in default theme.
  --fivef-color-button-primary: var(--fivef-color-blue-500);
  --fivef-color-button-primary-hover: var(--fivef-color-action);

  // The default color is a gray tone.
  --fivef-color-button-secondary: var(--fivef-color-fivef-blue-600);
  --fivef-color-button-secondary-hover: var(--fivef-color-grey-400);
  --fivef-color-button-disabled: var(--fivef-color-grey-400);
  --fivef-color-button-text: var(--fivef-color-text-inverse);
  --fivef-color-button-text-disabled: var(--fivef-color-text-disabled);
  --fivef-color-button-gray: var(--fivef-color-fivef-blue-600);

  --fivef-color-button-icon-text: var(--fivef-color-text-primary);
  --fivef-color-button-icon-text-disabled: var(--fivef-color-text-disabled);
  --fivef-color-button-icon-hover: var(--fivef-color-surface-secondary);
  // </Buttons>

  // <Inputs>
  --fivef-color-input-border: var(--fivef-color-border-primary);
  // 5F cool gray is working good on our main background, but the focus color is too strong and
  // a disabled color shade is missing, therefore a custom color set for now.
  //--fivef-color-input-background: var(--fivef-color-fivef-cool-gray-200);
  //--fivef-color-input-background-hover: var(--fivef-color-fivef-cool-gray-300);
  //--fivef-color-input-background-focus: var(--fivef-color-fivef-cool-gray-400);
  //--fivef-color-input-background-disabled: var(--fivef-color-fivef-cool-gray-200);

  --fivef-color-input-background: #eeefef;
  // Material fields have a layer on top changing the base color.
  // The generic background color mimics this behvior for custom fields.
  --fivef-color-input-background-generic: #ededf0;
  --fivef-color-input-background-hover: #e8e8eb;
  --fivef-color-input-background-focus: #dedee0;
  --fivef-color-input-background-disabled: #ededf0;

  --fivef-color-input-placeholder: var(--fivef-color-text-tertiary);
  --fivef-color-input-text: var(--fivef-color-text-primary);
  --fivef-color-input-focus-border: var(--fivef-color-blue-500);
  --fivef-color-input-error-border: var(--fivef-color-red-600);
  --fivef-color-input-disabled-background: var(--fivef-color-surface-disabled);
  --fivef-color-input-disabled-text: var(--fivef-color-text-disabled);
  // </Inputs>

  // <Dropdowns>
  --fivef-color-dropdown-border: var(--fivef-color-border-primary);
  --fivef-color-dropdown-background: var(--fivef-color-surface-primary);
  --fivef-color-dropdown-text: var(--fivef-color-text-primary);
  --fivef-color-dropdown-icon: var(--fivef-color-text-tertiary);
  --fivef-color-dropdown-hover: var(--fivef-color-grey-200);
  --fivef-color-dropdown-selected: var(--fivef-color-blue-500);
  // </Dropdowns>

  // <Dialog>
  --fivef-color-dialog-background: var(--fivef-color-surface-primary);
  --fivef-color-dialog-shadow: var(--fivef-box-shadow-l);
  --fivef-color-dialog-text: var(--fivef-color-text-primary);
  --fivef-color-dialog-button: var(--fivef-color-button-primary);
  --fivef-color-dialog-button-hover: var(--fivef-color-button-primary-hover);
  // </Dialog>

  // <Menu>
  --fivef-shadow-menu-xs: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  --fivef-shadow-menu-s: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  --fivef-shadow-menu-m: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  --fivef-shadow-menu-l: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  --fivef-shadow-menu-xl: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  // </Menu>

  // <Card>
  --fivef-color-card-background: var(--fivef-color-surface-primary);
  --fivef-color-card-border: var(--fivef-color-border-primary);
  --fivef-color-card-header-background: var(--fivef-color-surface-primary);
  --fivef-color-card-header-text: var(--fivef-color-text-primary);
  --fivef-color-card-content-text: var(--fivef-color-text-primary);
  --fivef-color-card-content-background: var(--fivef-color-surface-primary);
  --fivef-color-card-divider: var(--fivef-color-border-secondary);
  // </Card>

  // <Input>
  --fivef-input-color-background: var(--fivef-color-fivef-blue-300);
  --fivef-input-color-background-hover: var(--fivef-color-fivef-blue-600);
  --fivef-input-color-background-focus: var(--fivef-color-fivef-blue-700);
  // </Input>

  /**
   * fivef-alert-Coloring.
   */
  --fivef-alert-color-success: var(--fivef-color-success);
  --fivef-alert-color-warn: var(--fivef-color-warn);
  --fivef-alert-color-info: var(--fivef-color-info);
  --fivef-alert-color-default: var(--fivef-color-text-secondary);

  /**
   * fivef-avatar Coloring.
   */
  --fivef-avatar-color-background: #0090cf;
  --fivef-avatar-color-border: #0090cf;
  --fivef-avatar-color-initials: #FFFFFF;
  --fivef-avatar-color-status-member: var(--fivef-color-fivef-blue-900);
  --fivef-avatar-color-status-verified: var(--fivef-color-success);
  --fivef-avatar-color-shadow: var(--fivef-color-fivef-cool-gray-500);
  --fivef-avatar-box-shadow: 0 0 6px -3px var(--fivef-color-fivef-cool-gray-500);
  --fivef-avatar-status-shape: radial-gradient(circle, white 0%, white 50%, transparent 50%, transparent 100%);

  /**
   * Link setup.
   */
  --fivef-link-color: var(--fivef-color-action);
  --fivef-link-color-hover: #0090cf;
  --fivef-link-text-decoration: none;
  --fivef-link-text-decoration-hover: none;

  /**
   * Process breadcrumps
   */
  --fivef-process-path-breadcrumps-bg-color: #e9eaece6; // transparentize(#e9eaec, 0.1);
  --fivef-process-path-breadcrumps-hr-color: var(--fivef-color-separater);
  --fivef-process-path-breadcrumps-color-current: var(--fivef-color-text-primary);
  --fivef-process-path-breadcrumps-icon-color-current: var(--fivef-color-text-primary);
  --fivef-process-path-breadcrumps-structure-icon-color: var(--fivef-color-text-tertiary);
  --fivef-process-path-breadcrumps-color: var(--fivef-color-text-secondary);
  --fivef-process-path-breadcrumps-icon-color: var(--fivef-color-text-secondary);
  --fivef-process-path-breadcrumps-color-hover: var(--fivef-color-action);
  --fivef-process-path-breadcrumps-icon-color-hover: var(--fivef-color-action);
  --fivef-process-path-breadcrumps-text-decoration: none;
  --fivef-process-path-breadcrumps-text-decoration-hover: none;
  --fivef-process-path-breadcrumps-separator: #4e5a6a;

  /**
   * Dialog defaults.
   */
  --fivef-menu-color-surface: var(--fivef-color-surface-primary);
  --fivef-menu-border-radius: var(--fivef-border-radius-xs);
  --fivef-menu-elevation: var(--fivef-shadow-menu-m);

  /**
   * Timeline colors.
   */
  --fivef-timeline-content-bg-color: var(--fivef-color-fivef-cool-gray-300);
  --fivef-timeline-content-bg-color-light: var(--fivef-color-fivef-cool-gray-100);
  --fivef-timeline-separator-color: var(--fivef-color-fivef-cool-gray-300);
  --fivef-timeline-event-icon-color: #7b8490;

  /**
   * Indicator badges at workflows and dashboard and page header.
   */
  --fivef-indicator-color: #{$dvtx-fivef-blue-400};
  --fivef-indicator-background-color: #{$dvtx-fivef-blue-100};
  --fivef-indicator-color-unread: white;
  --fivef-indicator-background-color-unread: #{$dvtx-action-blue};

  /**
   * Loading indicator component.
   */
  --fivef-loading-indicator-progressbar-border-radius: 2px;

  /**
   * Project structure.
   */
  --fivef-process-tree-color: inherit;
  --fivef-process-tree-text-decoration: none;
  --fivef-process-tree-font-weight: normal;
  --fivef-process-tree-color-hover: var(--fivef-color-action);
  --fivef-process-tree-text-decoration-hover: none;
  --fivef-process-tree-font-weight-hover: inherit;
  --fivef-process-tree-color-active: var(--fivef-color-action);
  --fivef-process-tree-text-decoration-active: none;
  --fivef-process-tree-font-weight-active: bold;

  /**
   * In app notifications aka Material snackbar.
   */
  --fivef-notification-color-label: var(--fivef-color-text-primary);
  --fivef-notification-color-default: var(--fivef-color-text-inverse);
  --fivef-notification-color-bg-default: var(--fivef-color-text-primary);
  --fivef-notification-color-success: var(--fivef-color-text-primary);
  --fivef-notification-color-bg-success: var(--fivef-color-success-light);
  --fivef-notification-color-info: var(--fivef-color-text-primary);;
  --fivef-notification-color-bg-info: #{$dvtx-success-light};
  --fivef-notification-color-error: var(--fivef-color-text-primary);;
  --fivef-notification-color-bg-error: #{$dvtx-danger-light};
  --fivef-notification-color-warning: var(--fivef-color-text-primary);;
  --fivef-notification-color-bg-warning: #{$dvtx-warning-light};

  /**
   * Settings module panel.
   */
  --fivef-settings-panel-color: #FFF;
  --fivef-settings-panel-subtitle-color: rgba(255, 255, 255, 0.75);
  --fivef-settings-panel-icon-color: #FFF;
  --fivef-settings-panel-bg-color: #FFF;

  /**
   * Mat Table overrides.
   */
  --fivef-table-cell-bg-color: var(--fivef-color-surface-primary);
  --fivef-table-cell-bg-color-hover: var(--fivef-color-app-background);
  --fivef-table-header-cell-sticky-bg-color: #F5F5F5CC;
  // Formerly: Attention this creates a transparency that does not work nice on vertical scrolling with
  // sticky cells, e.g. template management.
  // --fivef-table-cell-bg-color-hover: lighten($dvtx-cool-grey-50, 3%);
  --fivef-table-border-color: var(--fivef-color-fivef-cool-gray-200);

  /**
   * Color bar at listing.
   */
  --fivef-color-bar-default: var(--fivef-color-fivef-cool-gray-500);

  // </Tokens>

  .fivef-theme-dark {
    --fivef-color-text-primary: var(--fivef-color-grey-100);
    --fivef-color-text-secondary: var(--fivef-color-grey-200);
    --fivef-color-text-tertiary: var(--fivef-color-grey-300);
    --fivef-color-text-disabled: var(--fivef-color-grey-400);
    --fivef-color-text-inverse: var(--fivef-color-dark-grey-900);
    --fivef-color-text-brand: var(--fivef-color-blue-500);

    --fivef-color-surface-primary: var(--fivef-color-dark-grey-900);
    --fivef-color-surface-secondary: var(--fivef-color-dark-grey-800);
    --fivef-color-surface-tertiary: var(--fivef-color-dark-grey-700);
    --fivef-color-surface-disabled: var(--fivef-color-dark-grey-600);
    --fivef-color-surface-inverse: var(--fivef-color-grey-100);
    --fivef-color-surface-brand: var(--fivef-color-blue-500);

    --fivef-color-border-primary: var(--fivef-color-dark-grey-700);
    --fivef-color-border-secondary: var(--fivef-color-dark-grey-600);
    --fivef-color-border-tertiary: var(--fivef-color-dark-grey-500);
    --fivef-color-border-disabled: var(--fivef-color-dark-grey-400);
    --fivef-color-border-inverse: var(--fivef-color-grey-100);
    --fivef-color-border-brand: var(--fivef-color-blue-500);

    --fivef-color-card-background: var(--fivef-color-surface-primary);
  }
}
